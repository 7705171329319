.aboutContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}

.aboutContentContainer {
  width: 60%;
  padding: 2rem;
  background-color: #444444;
  border-radius: 1rem;
  box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 30%);
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
}

.profilePictureContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profilePictureContainer a {
  width: fit-content;
}

.profilePictureContainer a > .profilePicture {
  height: 16rem;
  border-radius: 100%;
  transition: 300ms;
}

.profilePictureContainer a:hover > .profilePicture {
  box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.3);
}

.gitHubIcon {
  height: 3rem;
  position: relative;
  bottom: 0;
  left: -25%;
  border: 2px solid #ffffff;
  border-radius: 100%;
}

.aboutMeDetailsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.aboutMeDetailsContainer > p {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.aboutMeDetailsContainer > p > svg {
  height: 1.2rem;
  width: 1.2rem;
  margin-right: 0.5rem;
}

@media screen and (max-width: 1300px) {
  .aboutContentContainer {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .aboutContentContainer {
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }

  .profilePictureContainer a > .profilePicture {
    height: 12rem;
  }
}
