.footerContainer {
  background-color: #111;
  margin-top: 2rem;
}

.footerGradient {
  height: 4px;
  width: 100%;

  background-color: #21d4fd;
  background: linear-gradient(107.56deg, #532222 0%, #2b0c0c 64.06%);
}

.footerContentContainer {
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.footerContentContainer > span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerContentContainer > span > svg {
  height: 1.2rem;
  width: 1.2rem;
  margin-right: 0.5rem;
}

@media screen and (max-width: 570px) {
  .footerContentContainer {
    justify-content: space-between;
  }
  .developedBy {
    display: none;
  }
}
