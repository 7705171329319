@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&family=Poppins:wght@400;500;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: linear-gradient(107.56deg, #332525 0%, #1a1616 64.06%);
  color: #fff;
  font-family: Montserrat, 'sans-serif';
  font-size: 16px;
}

a {
  text-decoration: none;
  color: #fff;
}
