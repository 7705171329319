.navbarContainer {
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100vw;
  padding: 1rem 2rem;
  background: transparent;
  box-shadow: none;
  transition: background 200ms;
}

.navbarContainerScrolled {
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
}

.navbarLogo {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 1rem;
}

.navbarLogo img {
  height: 36px;
  margin-right: 2rem;
  transition: 200ms;
}

.navbarLogo p {
  font-family: Poppins, 'sans-serif';
  font-size: 1.2rem;
  opacity: 0%;
  transition: 200ms;
}

.navbarLogo:hover p {
  opacity: 100%;
}

.navbarLinksLink {
  margin-left: 8rem;
  text-decoration: none;
  color: #fff;
  font-family: Poppins, 'sans-serif';
  font-weight: bold;
  align-items: center;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 1rem;
  transition: 200ms;
}

.navbarLinksLink:hover {
  background: #fff;
  color: #444444;
  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.5);
}

.navbarLinksLink:hover img {
  filter: invert(0.8);
}

.navbarLinksLink img {
  margin-right: 1rem;
  height: 26px;
  transition: 200ms;
}

.navbarLinksLink > img {
  display: none;
}

.navbarLinksLink > div {
  display: flex;
}

.navbarMenuToggle {
  background: none;
  border: none;
  display: none;
  cursor: pointer;
}

.lateralNavbar {
  display: none;
  transition: 400ms;
  animation: ease-in-out;
}

.navbarCloseButton {
  background: #444;
  border-radius: 1rem;
  border: 0;
  padding: 1rem;
  display: none;
  cursor: pointer;
}

.navbarCloseButton img {
  height: 20px;
}

.lateralNavVisible {
  transform: translateX(80vw);
}

@media screen and (max-width: 1100px) {
  .navbarLinksLink {
    margin-left: 4rem;
  }
}

@media screen and (max-width: 900px) {
  .navbarLogo p {
    display: none;
  }

  .navbarMenuToggle {
    display: inline;
  }

  .navbarLinksContainer {
    display: none;
  }

  .lateralNavbar {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 80vw;
    height: 100%;
    top: 0;
    right: 0;
    background: #222;
    padding: 1rem;
    align-items: flex-end;

    box-shadow: -4px 0px 20px 0px rgba(0, 0, 0, 0.2);
  }

  .lateralNavbar a {
    display: block;
    background: #333;
    border-radius: 1rem;
    margin: 0;
    padding: 1rem;
    display: flex;
    margin-top: 1rem;
    width: 100%;
  }

  .navbarLinksLink > img {
    display: inline;
  }

  .navbarCloseButton {
    display: inline;
  }
}
