.skillsStackContainer {
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 1rem;
  column-gap: 1rem;
}

@media screen and (min-width: 2400px) {
  .skillsStackContainer {
    width: 70%;
    margin: auto;
  }
}

@media screen and (max-width: 1740px) {
  .skillsStackContainer {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1740px) {
  .skillsStackContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1100px) {
  .skillsStackContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 1100px) {
  .skillsStackContainer {
    grid-template-columns: 1fr;
  }
}
