@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&family=Poppins:wght@400;500;700&display=swap);
.Navbar_navbarContainer__2xZPo {
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100vw;
  padding: 1rem 2rem;
  background: transparent;
  box-shadow: none;
  transition: background 200ms;
}

.Navbar_navbarContainerScrolled__3AsUI {
  background: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
}

.Navbar_navbarLogo__arKe0 {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 1rem;
}

.Navbar_navbarLogo__arKe0 img {
  height: 36px;
  margin-right: 2rem;
  transition: 200ms;
}

.Navbar_navbarLogo__arKe0 p {
  font-family: Poppins, 'sans-serif';
  font-size: 1.2rem;
  opacity: 0%;
  transition: 200ms;
}

.Navbar_navbarLogo__arKe0:hover p {
  opacity: 100%;
}

.Navbar_navbarLinksLink__1aYAr {
  margin-left: 8rem;
  text-decoration: none;
  color: #fff;
  font-family: Poppins, 'sans-serif';
  font-weight: bold;
  align-items: center;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 1rem;
  transition: 200ms;
}

.Navbar_navbarLinksLink__1aYAr:hover {
  background: #fff;
  color: #444444;
  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.5);
}

.Navbar_navbarLinksLink__1aYAr:hover img {
  -webkit-filter: invert(0.8);
          filter: invert(0.8);
}

.Navbar_navbarLinksLink__1aYAr img {
  margin-right: 1rem;
  height: 26px;
  transition: 200ms;
}

.Navbar_navbarLinksLink__1aYAr > img {
  display: none;
}

.Navbar_navbarLinksLink__1aYAr > div {
  display: flex;
}

.Navbar_navbarMenuToggle__2kIkH {
  background: none;
  border: none;
  display: none;
  cursor: pointer;
}

.Navbar_lateralNavbar__2PP25 {
  display: none;
  transition: 400ms;
  animation: ease-in-out;
}

.Navbar_navbarCloseButton__3VvgB {
  background: #444;
  border-radius: 1rem;
  border: 0;
  padding: 1rem;
  display: none;
  cursor: pointer;
}

.Navbar_navbarCloseButton__3VvgB img {
  height: 20px;
}

.Navbar_lateralNavVisible__1PM8- {
  transform: translateX(80vw);
}

@media screen and (max-width: 1100px) {
  .Navbar_navbarLinksLink__1aYAr {
    margin-left: 4rem;
  }
}

@media screen and (max-width: 900px) {
  .Navbar_navbarLogo__arKe0 p {
    display: none;
  }

  .Navbar_navbarMenuToggle__2kIkH {
    display: inline;
  }

  .Navbar_navbarLinksContainer__Z9QmE {
    display: none;
  }

  .Navbar_lateralNavbar__2PP25 {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 80vw;
    height: 100%;
    top: 0;
    right: 0;
    background: #222;
    padding: 1rem;
    align-items: flex-end;

    box-shadow: -4px 0px 20px 0px rgba(0, 0, 0, 0.2);
  }

  .Navbar_lateralNavbar__2PP25 a {
    display: block;
    background: #333;
    border-radius: 1rem;
    margin: 0;
    padding: 1rem;
    display: flex;
    margin-top: 1rem;
    width: 100%;
  }

  .Navbar_navbarLinksLink__1aYAr > img {
    display: inline;
  }

  .Navbar_navbarCloseButton__3VvgB {
    display: inline;
  }
}

.Homepage_homepageContainer__1SubZ {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(107.56deg, #332525 0%, #1a1616 64.06%);
  height: 100vh;
  padding: 2rem;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
}

.Homepage_presentationContainer__iTems {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Homepage_presentationText__yc8SS {
  font-size: 4rem;
  width: 45%;
  transition: 200ms;
}

.Homepage_presentationText__yc8SS p {
  margin-top: 0.5rem;
  font-size: 2rem;
  transition: 200ms;
}

.Homepage_presentationAbout__1RUef {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transition: 200ms;
}

.Homepage_presentationAbout__1RUef h1 {
  font-size: 4rem;
  transition: 200ms;
}

.Homepage_presentationAbout__1RUef p {
  font-size: 2rem;
  transition: 200ms;
}

.Homepage_presentationStack__2V892 {
  border: 2px solid rgba(0, 0, 0, 0);
  transition: 200ms;
  margin-top: 1rem;
  padding: 1rem 0;
  border-radius: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.Homepage_presentationStack__2V892 .Homepage_presentationStackPlaceholder__Z2C01 {
  display: flex;
  align-items: center;
  opacity: 0%;
  transition: 200ms;
  color: #fff;
}

.Homepage_presentationStack__2V892:hover .Homepage_presentationStackPlaceholder__Z2C01 {
  opacity: 100%;
  margin-top: 1rem;
}

.Homepage_presentationStack__2V892 .Homepage_presentationStackPlaceholder__Z2C01 img {
  height: 1.2rem;
  box-shadow: none;
  margin-left: 0.6rem;
}

.Homepage_presentationStack__2V892:hover {
  border: 2px solid #444444;
  padding: 1rem;
}

.Homepage_presentationStack__2V892:hover > .Homepage_stackIconsContainer__2iJR1 img {
  margin-left: 0.5rem;
}

.Homepage_presentationStack__2V892 img {
  height: 3.5rem;
  margin-left: -1rem;
  box-shadow: -6px 0px 15px 0px rgba(0, 0, 0, 0.3);
  transition: 300ms;
  animation-name: Homepage_stackExpand__1-ZgL;
  animation-duration: 2s;
  animation-delay: 0.5s;
}

.Homepage_footerButton__H6evI {
  width: 15rem;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 1rem;
  font-family: Poppins, "sans-serif";
  font-weight: medium;
  font-size: 1.1rem;
  color: #fff;
  padding: 1rem;
  cursor: pointer;
  display: flex;
  transition: 200ms;
  justify-content: center;
  align-items: center;
}

.Homepage_footerButtonImg__2Jcz5 {
  height: 2rem;
  margin-right: 0.5rem;
  transition: 200ms;
}

.Homepage_footerButton__H6evI:hover {
  background: #fff;
  color: #444444;
  width: 18rem;

  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.5);
}

.Homepage_footerButton__H6evI:hover .Homepage_footerButtonImg__2Jcz5 {
  -webkit-filter: invert(0.8);
          filter: invert(0.8);
}

.Homepage_stackDetailsContainer__3Np4V {
  padding: 2rem;
}

.Homepage_homepageTitle__33zBY {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  margin: 2rem 0;
  text-align: center;
}

.Homepage_aboutSectionContainer__3VS6Q {
  background: #444444;
  padding: 1rem;
  border-radius: 1rem;
  margin-bottom: 4rem;
}

.Homepage_scrollIndicatorContainer__79q2T {
  position: absolute;
  left: 50%;
  bottom: 2rem;
  animation: Homepage_itemFloat__CsPwY;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes Homepage_stackExpand__1-ZgL {
  0% {
    margin-left: -1rem;
  }

  15% {
    margin-left: 0.5rem;
  }

  85% {
    margin-left: 0.5rem;
  }

  100% {
    margin-left: -1rem;
  }
}

@keyframes Homepage_itemFloat__CsPwY {
  0% {
    transform: translateY(0rem);
  }

  100% {
    transform: translateY(-1rem);
  }
}

@media screen and (max-width: 1100px) {
  .Homepage_presentationText__yc8SS {
    font-size: 3rem;
  }

  .Homepage_presentationAbout__1RUef h1 {
    font-size: 3rem;
  }

  .Homepage_presentationAbout__1RUef p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 900px) {
  .Homepage_presentationContainer__iTems {
    flex-direction: column;
  }

  .Homepage_presentationText__yc8SS {
    margin-top: -4rem;
    font-size: 1.8rem;
    width: 100%;
  }

  .Homepage_presentationAbout__1RUef {
    width: 100%;
    margin-top: 2rem;
  }

  .Homepage_presentationAbout__1RUef h1 {
    font-size: 2rem;
  }

  .Homepage_presentationAbout__1RUef p {
    font-size: 1rem;
  }

  .Homepage_presentationStack__2V892 img {
    height: 2.5rem;
  }

  .Homepage_footerButton__H6evI {
    font-size: 1rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 4rem;
  }

  .Homepage_footerButton__H6evI:hover {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .Homepage_presentationStack__2V892:hover > .Homepage_stackIconsContainer__2iJR1 img {
    margin-left: -0.5rem;
  }

  .Homepage_homepageTitle__33zBY {
    font-size: 1.5rem;
  }
}

.AboutMe_aboutContainer__3juEp {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}

.AboutMe_aboutContentContainer__14zpc {
  width: 60%;
  padding: 2rem;
  background-color: #444444;
  border-radius: 1rem;
  box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 30%);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
}

.AboutMe_profilePictureContainer__1BVVQ {
  display: flex;
  align-items: center;
  justify-content: center;
}

.AboutMe_profilePictureContainer__1BVVQ a {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.AboutMe_profilePictureContainer__1BVVQ a > .AboutMe_profilePicture__2XjRv {
  height: 16rem;
  border-radius: 100%;
  transition: 300ms;
}

.AboutMe_profilePictureContainer__1BVVQ a:hover > .AboutMe_profilePicture__2XjRv {
  box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.3);
}

.AboutMe_gitHubIcon__2zhUT {
  height: 3rem;
  position: relative;
  bottom: 0;
  left: -25%;
  border: 2px solid #ffffff;
  border-radius: 100%;
}

.AboutMe_aboutMeDetailsContainer__P6R1F {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.AboutMe_aboutMeDetailsContainer__P6R1F > p {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.AboutMe_aboutMeDetailsContainer__P6R1F > p > svg {
  height: 1.2rem;
  width: 1.2rem;
  margin-right: 0.5rem;
}

@media screen and (max-width: 1300px) {
  .AboutMe_aboutContentContainer__14zpc {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .AboutMe_aboutContentContainer__14zpc {
    grid-template-columns: 1fr;
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }

  .AboutMe_profilePictureContainer__1BVVQ a > .AboutMe_profilePicture__2XjRv {
    height: 12rem;
  }
}

.ContactCard_contactContainer__9ZKfs {
  margin-top: 2rem;
  border-radius: 1rem;
  background-color: #444444;
  width: 70%;
  margin: auto;
  display: flex;
  flex-direction: row;
}

.ContactCard_contactTitle__qhncN {
  padding: 3rem 2rem;
  border-radius: 1rem 0 0 1rem;
  background-color: #666;
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.3rem;
  text-align: center;
}

.ContactCard_contactLinks__3-_Un {
  width: 70%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  padding: 2rem;
}

.ContactCard_contactLinks__3-_Un a {
  font-size: 1rem;
  border-radius: 1rem;
  border: 2px solid #888;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  font-weight: bold;

  transition: 200ms;
}

.ContactCard_contactLinks__3-_Un a:hover {
  background: #fff;
  color: #121214;
  border: 2px solid #fff;
  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.5);
}

.ContactCard_contactLinks__3-_Un a svg {
  font-size: 1.6rem;
  margin-right: 0.5rem;
}

@media screen and (max-width: 1500px) {
  .ContactCard_stackDetailsCardsContainer__wmgKL {
    grid-template-columns: repeat(2, 1fr);
  }

  .ContactCard_contactContainer__9ZKfs {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .ContactCard_contactContainer__9ZKfs {
    flex-direction: column;
  }

  .ContactCard_contactTitle__qhncN {
    width: 100%;
    padding: 2rem;
    border-radius: 1rem 1rem 0 0;
  }

  .ContactCard_contactLinks__3-_Un {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 1rem;
    row-gap: 1rem;
    padding: 2rem;
  }

  .ContactCard_contactLinks__3-_Un a {
    padding: 1rem;
  }
}

.SkillStack_skillsStackContainer__nUY5M {
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 1rem;
  row-gap: 1rem;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
}

@media screen and (min-width: 2400px) {
  .SkillStack_skillsStackContainer__nUY5M {
    width: 70%;
    margin: auto;
  }
}

@media screen and (max-width: 1740px) {
  .SkillStack_skillsStackContainer__nUY5M {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1740px) {
  .SkillStack_skillsStackContainer__nUY5M {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1100px) {
  .SkillStack_skillsStackContainer__nUY5M {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 1100px) {
  .SkillStack_skillsStackContainer__nUY5M {
    grid-template-columns: 1fr;
  }
}

.SkillStackItem_itemContainer__qLyRA {
  display: flex;
  flex-direction: row;
  justify-self: center;
}

.SkillStackItem_itemContainer__qLyRA .SkillStackItem_iconContainer__7qWGW {
  padding: 1rem;
  border-radius: 1rem 0 0 1rem;
  background: #555555;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SkillStackItem_itemContainer__qLyRA .SkillStackItem_iconContainer__7qWGW img {
  height: 3rem;
}

.SkillStackItem_itemContainer__qLyRA .SkillStackItem_detailsContainer__1uTWN {
  padding: 1rem;
  background: #666666;
  border-radius: 0 1rem 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.SkillStackItem_itemContainer__qLyRA .SkillStackItem_detailsContainer__1uTWN .SkillStackItem_titleContainer__eu71s {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.25rem;
}

.SkillStackItem_itemContainer__qLyRA .SkillStackItem_detailsContainer__1uTWN .SkillStackItem_titleContainer__eu71s span {
  margin-right: 2rem;
  font-size: 1.5rem;
}

.SkillStackItem_itemContainer__qLyRA .SkillStackItem_detailsContainer__1uTWN .SkillStackItem_levelContainer__1LZ0K {
  display: flex;
}
.SkillStackItem_itemContainer__qLyRA .SkillStackItem_detailsContainer__1uTWN .SkillStackItem_levelContainer__1LZ0K > div {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 100%;
  margin-right: 0.5rem;
  background: #444444;
}

.Footer_footerContainer__3Dl-6 {
  background-color: #111;
  margin-top: 2rem;
}

.Footer_footerGradient__1BfLi {
  height: 4px;
  width: 100%;

  background-color: #21d4fd;
  background: linear-gradient(107.56deg, #532222 0%, #2b0c0c 64.06%);
}

.Footer_footerContentContainer__25neW {
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.Footer_footerContentContainer__25neW > span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Footer_footerContentContainer__25neW > span > svg {
  height: 1.2rem;
  width: 1.2rem;
  margin-right: 0.5rem;
}

@media screen and (max-width: 570px) {
  .Footer_footerContentContainer__25neW {
    justify-content: space-between;
  }
  .Footer_developedBy__s_FYG {
    display: none;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: linear-gradient(107.56deg, #332525 0%, #1a1616 64.06%);
  color: #fff;
  font-family: Montserrat, 'sans-serif';
  font-size: 16px;
}

a {
  text-decoration: none;
  color: #fff;
}

