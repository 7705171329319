.contactContainer {
  margin-top: 2rem;
  border-radius: 1rem;
  background-color: #444444;
  width: 70%;
  margin: auto;
  display: flex;
  flex-direction: row;
}

.contactTitle {
  padding: 3rem 2rem;
  border-radius: 1rem 0 0 1rem;
  background-color: #666;
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.3rem;
  text-align: center;
}

.contactLinks {
  width: 70%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
  padding: 2rem;
}

.contactLinks a {
  font-size: 1rem;
  border-radius: 1rem;
  border: 2px solid #888;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  font-weight: bold;

  transition: 200ms;
}

.contactLinks a:hover {
  background: #fff;
  color: #121214;
  border: 2px solid #fff;
  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.5);
}

.contactLinks a svg {
  font-size: 1.6rem;
  margin-right: 0.5rem;
}

@media screen and (max-width: 1500px) {
  .stackDetailsCardsContainer {
    grid-template-columns: repeat(2, 1fr);
  }

  .contactContainer {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .contactContainer {
    flex-direction: column;
  }

  .contactTitle {
    width: 100%;
    padding: 2rem;
    border-radius: 1rem 1rem 0 0;
  }

  .contactLinks {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 1rem;
    padding: 2rem;
  }

  .contactLinks a {
    padding: 1rem;
  }
}
